import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    auth: false,
    token: '',
    users: 0,
    reports: [],
    tokenexpirytime: '',
    claimdetails: false,
    transactions: false,
    reportsPDF: false,
    reportsCSV: false,
    reportsXLS: false,
    reportsJSON: false,
    reportsLOG: false,
    reportsNOBARCODE: false,
    reportsSPLIT: false,
    cycleid: undefined
}

export const appmgtSlice = createSlice({
    name: 'appmgt',
    initialState,
    reducers: {
        setAuth: (state, action) => {
            state.auth = action.payload
        },
        setToken: (state, action) => {
            state.token = action.payload
        },
        setUsers: (state, action) => {
            state.users = action.payload
        },
        setTokenExpiryTime: (state, action) => {
            state.tokenexpirytime = action.payload
        },
        setClaimDetails: (state, action) => {
            state.claimdetails = action.payload
        },
        setTransactions: (state, action) => {
            state.transactions = action.payload
        },

        setReportsPDF: (state, action) => {
            state.reportsPDF = action.payload
        },
        setReportsCSV: (state, action) => {
            state.reportsCSV = action.payload
        },
        setReportsXLS: (state, action) => {
            state.reportsXLS = action.payload
        },
        setReportsJSON: (state, action) => {
            state.reportsJSON = action.payload
        },
        setReportsLOG: (state, action) => {
            state.reportsLOG = action.payload
        },
        setReportsNOBARCODE: (state, action) => {
            state.reportsNOBARCODE = action.payload
        },
        setReportsSPLIT: (state, action) => {
            state.reportsSPLIT = action.payload
        },
        setCycleId: (state, action) => {
            state.cycleid = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setAuth, setToken, setUsers, setTokenExpiryTime, setClaimDetails, setTransactions, setReportsPDF, setReportsCSV, setReportsXLS, setReportsJSON, setCycleId, setReportsLOG, setReportsNOBARCODE, setReportsSPLIT } = appmgtSlice.actions

export default appmgtSlice.reducer
import React from "react";
import { Descriptions } from "antd";

export const DesriptionProgress = ({
  total,
  processed,
  remaining,
  counted,
  claimed,
}) => (
  <Descriptions title="Current Stock Run - Details" bordered column={2}>
    <Descriptions.Item label="Claimed Value"> € {claimed.toFixed(2)}</Descriptions.Item>
    <Descriptions.Item label="Counted Value"> € {counted.toFixed(2)}</Descriptions.Item>
    <Descriptions.Item label="Total Claims" span={2}>
      {total}
    </Descriptions.Item>
    <Descriptions.Item label="Processed Claims" span={2}>
      {processed}
    </Descriptions.Item>
    <Descriptions.Item label="Remaining Claims" span={2}>
      {remaining}
    </Descriptions.Item>
  </Descriptions>
);



import "../css/App.css";
import { UserOutlined, BookOutlined, FileOutlined } from '@ant-design/icons';
import { Layout, Menu, Col, Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faUsersViewfinder, faGear, faPersonWalkingDashedLineArrowRight, faArrowsSpin } from '@fortawesome/free-solid-svg-icons'
import { Navigate, useOutlet, NavLink, useNavigate } from "react-router-dom";

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { setAuth } from '../redux/appmgtSlice'

//Logo
import logo from '../assets/top.png'

export const ProtectedLayout = () => {

    //Redux
    const dispatch = useDispatch()
    const authentication = useSelector((state) => state.appmgt.auth);   

    //Navigation
    const navigate = useNavigate();

    //Set Layout
    const { Header, Sider, Content } = Layout;

    ///const { user } = useAuth();
    const outlet = useOutlet();

    if (!authentication) {
        return <Navigate to="/" />;
    }
 
    //Side Menu Config
    function getItem(label, key, icon, children) {
        return {
            key,
            icon,
            children,
            label,
        };
    }

    //Side Menu Config
    const items = [
        getItem('Manage Personnel', '/dashboard/users', <UserOutlined />),
        getItem('Claims', 'sub2', <BookOutlined />, [getItem('Upload', '/dashboard/claimUpload')]),
        getItem('Cycle Data', 'sub3', <BookOutlined />, [getItem('Distribution', '/dashboard/distribution'), getItem('Categories', '/dashboard/categories'), getItem('Claim Details', '/dashboard/claimDetails'), getItem('Transactions', '/dashboard/transactionDetails')]),
        getItem('Reports', 'sub4', <FileOutlined />, [getItem('Reports', '/dashboard/reports')])
    ];

    const onClick = ({ key }) => {
        if (key) {
            navigate(key);
        }
    };

    function logout() {
        console.log('logout');
        dispatch(setAuth(false))
    }

    return (
        <div className="main" >
            <Layout style={{ minHeight: '98vh' }}>
                <Sider trigger={null} collapsible collapsed={false} style={{ background: '#ffffff' }} >
                    <div className="header_title" >
                        <img src={logo} alt="Logo" />
                    </div>
                    <Menu defaultSelectedKeys={['1']} mode="inline" items={items} onClick={onClick} />
                </Sider>

                <Layout>
                    <Header className="header_body">
                        <Row className="nav">
                            {/* <Col span={1}>

                                <Button
                                    type="text"
                                    icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                                    onClick={() => setCollapsed(!collapsed)}
                                    style={{
                                        fontSize: '16px',
                                        width: 64,
                                        height: 64,
                                    }}
                                />
                            </Col> */}
                            <Col span={2}>
                                <NavLink to="/dashboard/home"><FontAwesomeIcon icon={faHouse} size="2xl" inverse className="headericons" /></NavLink>
                                <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to="/dashboard/home">Home</NavLink>
                            </Col>
                            <Col span={2}>
                                <NavLink to="/dashboard/managecycle"><FontAwesomeIcon icon={faArrowsSpin} size="2xl" inverse className="headericons" /></NavLink>
                                <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to="/dashboard/managecycle">Manage Cycle</NavLink>
                            </Col>
                            <Col span={2}>
                                <NavLink to="/dashboard/status"><FontAwesomeIcon icon={faUsersViewfinder} size="2xl" inverse className="headericons" /></NavLink>
                                <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to="/dashboard/status">Status</NavLink>
                            </Col>
                            <Col span={2}>
                                <NavLink to="/dashboard/settings"><FontAwesomeIcon icon={faGear} size="2xl" inverse className="headericons" /></NavLink>
                                <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to="/dashboard/settings">Settings</NavLink>
                            </Col>
                            <Col span={16} className="logout">
                                <FontAwesomeIcon icon={faPersonWalkingDashedLineArrowRight} size="2xl" inverse className="headericons" onClick={logout} />
                            </Col>
                        </Row>
                    </Header>
                    <Content className="content">
                        {outlet}
                    </Content>
                </Layout>
            </Layout>

        </div>
    );
};
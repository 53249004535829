import { Navigate, Outlet } from "react-router-dom";
import { useSelector} from 'react-redux';

export const HomeLayout = () => {

  const authentication = useSelector((state) => state.appmgt.auth)

  //const authentication = false;

  if (authentication) {
    return <Navigate to="/dashboard/home" />;
  }

  return (
    <div className="main"> <Outlet /></div>
  )
};
import React from "react";
import { RingProgress } from "@ant-design/plots";

export function Progress({progress}) {
  const config = {
    height: 200,
    width: 200,
    autoFit: false,
    percent: progress,
    color: ["#80ed99", "#E8EDF3"],
    statistic: {
      title: {
        style: {
          color: '#363636',
          fontSize: '11px',   
        },
        formatter: () => 'completed',
      },
    },
    
  };  

  return <RingProgress {...config} />;
};

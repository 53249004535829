import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCheck, faUserXmark } from '@fortawesome/free-solid-svg-icons'
import { Descriptions, Row, Col, Alert } from 'antd';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { setAuth, setCycleId } from '../redux/appmgtSlice';

//Environment
import { chooseEnvironment } from '../utils/chooseEnvironment';

export function Status() {

    //Redux
    const dispatch = useDispatch();
    const token = useSelector((state) => state.appmgt.token);
    const currentcycleid = useSelector((state) => state.appmgt.cycleid);
    //State
    const [users, setUsers] = useState([]);

    let comment = "This page shows personnel successfully registered to the current stock cycle [ last uploaded ]. Personnel shown as 'x' may have not opened their app or are out of reach of a wi-fi or mobile network. You may wait or take the necessary steps to reach them. [ cycleid: " + currentcycleid + " ]"

    //Environment
    let envUrl = chooseEnvironment();
    const refreshrate = process.env.REACT_APP_USERSREFRESHRATE;

    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            "x-auth-token": token,
        },
    };

    useEffect(() => {

        const values = {
            "company": process.env.REACT_APP_COMPANY
        };

        const interval = setInterval(() => {
            Axios.post(envUrl + '/api/getpersonellstatus', values, customConfig).then(function (response) {

                if (response.data.token) {
                    let array = response.data.users;
                    let lastcycleid = response.data.lastcycleid;
                    console.log(lastcycleid);
                    dispatch(setCycleId(lastcycleid));

                    doAll(array, lastcycleid);
                } else {
                    dispatch(setAuth(response.data.token));
                }
            });
        }, refreshrate); //set your time here.

        return () => clearInterval(interval);

        async function doAll(users, lastcycleid) {
            let cleanarray = await removeAdmin(users);

            await cleanarray.forEach(obj => checkKey(obj, lastcycleid));
            await cleanarray.forEach(obj => renameKey(obj, '_id', 'key'));
            await cleanarray.forEach(obj => renameKey(obj, 'username', 'label'));
            setUsers(cleanarray);

        }

        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Row>
                <Col span={1} className="settingsheader"></Col>
                <Col span={12} className="settingsheader">
                    <Descriptions title="Personnel - Cycle Status" bordered items={users} layout="vertical" />
                </Col>
                <Col span={11} className="settingsheader"></Col>
            </Row>
            <Row>
                <Col span={1} className="settingsheader"></Col>
                <Col span={12} className="settingsheader">
                    <Alert
                        message="Note"
                        description={comment}
                        type="info"
                        showIcon
                    />
                </Col>
                <Col span={11} className="settingsheader"></Col>
            </Row>
            <Row>
                <Col span={24} className="settingsbottom"></Col>
            </Row>
        </>
    )
}

function renameKey(obj, oldKey, newKey) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
}

function checkKey(obj, tempcycleid) {
    if (obj.cycleid === tempcycleid) {
        obj['children'] = <FontAwesomeIcon icon={faUserCheck} size="2xl" />;
    } else {
        obj['children'] = <FontAwesomeIcon icon={faUserXmark} size="2xl" style={{ color: "#d9d9d9", }} />;
    }
}

function removeAdmin(array) {
    let cleanarray = array.filter(function (value) {
        return value.username !== 'admin';
    });
    return cleanarray;
}
import React, { useState, useEffect } from "react";
import "../css/Home.css";
import { Col, Row, Alert } from 'antd';
import { DesriptionProgress } from "./stats/DescriptionProgress";
import { Progress } from "./stats/Progress";
import { Value } from "./stats/Value";
import { Typography } from "antd";
import Axios from 'axios';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { setAuth } from '../redux/appmgtSlice'

//Environment
import { chooseEnvironment } from '../utils/chooseEnvironment';

const { Title } = Typography;

export function Home() {

    //Environment
    let envUrl = chooseEnvironment();
    const refreshrate = process.env.REACT_APP_HOMEREFRESHRATE;

    //Redux
    const dispatch = useDispatch();
    const token = useSelector((state) => state.appmgt.token);    
    const tokenexpirytime = useSelector((state) => state.appmgt.tokenexpirytime);

    const [totalClaims, setTotalClaims] = useState(0);
    const [remainingHits, setRemainingHits] = useState(0);
    const [progmade, setProgMade] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const [counted, setCounted] = useState(0);
    const [claimed, setClaimed] = useState(0);

    useEffect(() => {

        const customConfig = {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": token,
            },
        };

        const values = {
            "company": process.env.REACT_APP_COMPANY
        };

        const interval = setInterval(() => {     

            Axios.post(envUrl + '/api/getHomeValues', values, customConfig).then(function (response) {

                console.log(response.data);

                dispatch(setAuth(response.data.token));
                setTotalClaims(response.data.totalclaims);
                setRemainingHits(response.data.remaininghits);
                setProgMade(response.data.progmade);
                setPercentage(response.data.percentage);
                setCounted(response.data.countedvalue);
                setClaimed(response.data.claimedvalue);
            });

        }, refreshrate); //set your time here. repeat every 5 seconds

        return () => clearInterval(interval);

        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Row >
                <Col span={2} className="hometitle"></Col>
                <Col span={10} className="hometitle">
                    <Title level={4}>Progress</Title>
                </Col>
                <Col span={10} className="hometitle">
                    <Title level={4}>Value</Title>
                </Col>
                <Col span={2} className="hometitle"></Col>
            </Row>

            <Row >
                <Col span={2} className="hometop"></Col>
                <Col span={10} className="hometop">
                    <Progress progress={percentage} />
                </Col>
                <Col span={10} className="hometop"><Value counted={counted} claimed={claimed} /></Col>
                <Col span={2} className="hometop"></Col>
            </Row>

            <Row >
                <Col span={2} className="homebottom"></Col>
                <Col span={20} className="homebottom">
                    <DesriptionProgress
                        total={totalClaims}
                        processed={progmade}
                        remaining={remainingHits}
                        counted={counted}
                        claimed={claimed}
                    /></Col>
                <Col span={2} className="homebottom"></Col>
            </Row>

            <Row>
                <Col span={2} className="fileuploadinfo"></Col>
                <Col span={20} className="fileuploadinfo">
                    <Alert
                        message="Tip"
                        description="This page is showing the progress [ live ] of the products being counted. The values in [ € ] are being updated per claim."
                        type="info"
                        showIcon
                    />
                </Col>
                <Col span={2} className="fileuploadinfo"></Col>
            </Row>
            <Row >
                <Col span={2} className="homerefreshrate"></Col>
                <Col span={20} className="homerefreshrate"><Title level={5}>Auto Refresh - every {refreshrate / 1000} seconds, Auto Logout - every {tokenexpirytime}</Title></Col>
                <Col span={2} className="homerefreshrate"></Col>
            </Row>
        </>
    );
}
import "../css/LiveCounting.css";
import { Table, Col, Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons'


export function LiveCounting() {

    const expandedRowRender = () => {

        const columns = [
            {
                title: 'By',
                dataIndex: 'by_s',
                key: 'by_s',
            },
            {
                title: 'Type',
                dataIndex: 'type_s',
                key: 'type_s',
            },
            {
                title: 'Counted',
                dataIndex: 'counted_i',
                key: 'counted_i',
            },
            {
                title: 'Expiry Date',
                dataIndex: 'expiry_dt',
                key: 'expiry_dt',
            },
            {
                title: 'Transaction Date',
                dataIndex: 'timestamp_dt',
                key: 'timestamp_dt',
            }
        ];

        const data = [];
        for (let i = 0; i < 3; ++i) {
            data.push({
                key: i.toString(),
                by_s: 'sjxuereb',
                type_s: 'stockcount',
                counted_i: 50,
                expiry_dt: '2014-12-24 23:12:00',
                timestamp_dt: '2014-12-24 23:12:00',
            });
        }

        return <Table columns={columns} dataSource={data} pagination={false} />;
    };

    const columns = [
        {
            title: 'Barcode',
            dataIndex: 'barcode_s',
            key: 'barcode',
        },
        {
            title: 'SKU',
            dataIndex: 'sku_s',
            key: 'sku_s',
        },
        {
            title: 'Description',
            dataIndex: 'description_s',
            key: 'description_s',
        },
        {
            title: 'Location',
            dataIndex: 'location_s',
            key: 'location_s',
        },
        {
            title: 'Claim',
            dataIndex: 'claim_i',
            key: 'claim_i',
        },
        {
            title: 'Counted',
            dataIndex: 'counted_i',
            key: 'counted_i',
        },
        {
            title: 'Difference',
            dataIndex: 'result_i',
            key: 'result_i',
        },
        {
            title: 'Last Updated',
            dataIndex: 'lastupdated_dt',
            key: 'lastupdated_dt',
        }
    ];

    const data = [];
    for (let i = 0; i < 15; ++i) {
        data.push({
            key: i.toString(),
            barcode_s: '123456',
            sku_s: '64321',
            description_s: "Cote D'or 20mg",
            location_s: 'Qormi',
            claim_i: 500,
            counted_i: 50,
            result_i: 450,
            lastupdated_dt: '2014-12-24 23:12:00',
        });
    }

    return (
        <>
            <Row>               
                <Col span={22} className="livecountingboxestop">
                   
                </Col>   
                <Col span={2} className="livecountingboxestop">
                    <div className="refresh"><FontAwesomeIcon icon={faArrowsRotate} size="xl" /> Refresh</div>
                </Col>                  
            </Row>
            <Row>     
                <Col span={24} className="livecountingboxesbottom">
                    <Table
                        columns={columns}
                        expandable={{
                            expandedRowRender
                        }}
                        dataSource={data}
                        size="small"
                        pagination={false}
                    />
                </Col>         
            </Row>

        </>
    );
};
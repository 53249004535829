import "../../css/ReportsLocation.css";
import { Descriptions, Col, Row, Button } from 'antd';
import Axios from 'axios';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv, faFilePdf, faFontAwesome } from '@fortawesome/free-solid-svg-icons';

//Tool Tip 
import { Tooltip as ReactTooltip } from "react-tooltip";

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { setAuth } from '../../redux/appmgtSlice'

//Environment
import { chooseEnvironment } from '../../utils/chooseEnvironment';

async function getHelpfile() {

    const apiHost = window.location.hostname;
    const apiPort = window.location.port;
    const currentProtocol = window.location.protocol;

    Axios({
        url: currentProtocol + '//' + apiHost + ':' + apiPort + '/downloads/docs/interpreting_trakii_reports.pdf',
        method: 'GET',
        responseType: 'blob', // important
    }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'interpreting_trakii_reports.pdf');
        document.body.appendChild(link);
        link.click();
    });


}

export const Reports = (propz) => {

    //Redux
    const dispatch = useDispatch();
    const token = useSelector((state) => state.appmgt.token);

    //Packages Config
    const reportsPDF = useSelector((state) => state.appmgt.reportsPDF);
    const reportsCSV = useSelector((state) => state.appmgt.reportsCSV);
    const reportsXLS = useSelector((state) => state.appmgt.reportsXLS);
    const reportsJSON = useSelector((state) => state.appmgt.reportsJSON);
    const reportsLOG = useSelector((state) => state.appmgt.reportsLOG);
    const reportsNOBARCODE = useSelector((state) => state.appmgt.reportsNOBARCODE);
    const reportsSPLIT = useSelector((state) => state.appmgt.reportsSPLIT);

    //Environment
    let envUrl = chooseEnvironment();
    const [items, setItems] = useState([]);
    const [items2, setItems2] = useState([]);
    const [items3, setItems3] = useState([]);
    const [items4, setItems4] = useState([]);
    const [items5, setItems5] = useState([]);

    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            "x-auth-token": token,
        },
    };

    //By Location
    const runReports = async () => {

        const values = {
            "company": process.env.REACT_APP_COMPANY
        }

        await Axios.post(envUrl + '/api/reports', values, customConfig)
            .then(function (response) {
                if (response.data.token) {

                    if (!response.data.reports) {
                        console.log('returned null - undefined');
                    } else {

                        let filtered = filterArray(response.data.reports, reportsCSV, reportsXLS, reportsJSON, reportsLOG);
                        filtered.then((x) => {
                            setItems(x);
                        });

                        let filtered2 = filterArray2(response.data.reports, reportsPDF);
                        filtered2.then((x) => {
                            setItems2(x);
                        });

                        let filtered3 = filterArray3(response.data.reports, reportsPDF);
                        filtered3.then((x) => {
                            setItems3(x);
                        });

                        let filtered4 = filterArray4(response.data.reports, reportsNOBARCODE);
                        filtered4.then((x) => {
                            setItems4(x);
                        });

                        let filtered5 = filterArray5(response.data.reports, reportsSPLIT);
                        filtered5.then((x) => {
                            setItems5(x);
                        });

                    }
                } else {
                    dispatch(setAuth(response.data.token));
                };
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    //By Location
    //const downloadReport = async (filename, type) => {
    async function downloadReport(filename, type) {

        const values = {
            "filename": filename,
            "type": type
        }

        const customConfig = {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": token,
            },
        };

        await Axios.post(envUrl + '/api/checkToken', values, customConfig)
            .then(function (response) {

                if (response.data.token) {
                    downloadFile(filename, type);
                } else {
                    dispatch(setAuth(response.data.token));
                };
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    async function downloadFile(filename, type) {
        Axios({
            url: envUrl + '/api/downloadreport',
            method: 'GET',
            responseType: 'blob',
            params: { filename: filename, type: type }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        });
    }

    return (
        <>

            <Row >
                <Col span={1} className="fileuploadboxestop"></Col>
                <Col span={16} className="fileuploadboxestop">
                    <Descriptions title="Reports" bordered>
                        <Descriptions.Item label="Click to generate fresh reports">
                            <Button type="primary" onClick={() => runReports()}>Generate</Button>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
                <Col span={7} className="fileuploadboxestop"></Col>
            </Row>

            <Row >
                <Col span={1} className="fileuploadboxestop"></Col>
                <Col span={16} className="fileuploadboxestop">
                    <Descriptions title="Help" bordered>
                        <Descriptions.Item label="Documentation on how to interpret reports">
                            <FontAwesomeIcon data-tooltip-id="my-tooltip-1" data-tooltip-html="This is your detailed help file on how to interpret the reports.<br /> Click to download." icon={faFilePdf} size="2xl" onClick={() => { getHelpfile() }} />
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
                <Col span={7} className="fileuploadboxestop"></Col>
            </Row>

            <Row >
                <Col span={1} className="fileuploadboxesbottom"></Col>
                <Col span={16} className="fileuploadboxesbottom">
                    <Descriptions title="General Reports" bordered >
                        {items.length > 0 && items.map((item) =>
                            <Descriptions.Item label={item.location + ' - [ ' + item.timestamp + ' ]'} key={item.id}>                                
                                {item.type === 'csv' && <FontAwesomeIcon data-tooltip-id="my-tooltip-1" data-tooltip-html={item.tooltip} onClick={() => { downloadReport(item.filename, item.type) }} icon={faFileCsv} size="2xl" />}
                                {item.type === 'log' && <FontAwesomeIcon data-tooltip-id="my-tooltip-1" data-tooltip-html={item.tooltip} onClick={() => { downloadReport(item.filename, item.type) }} icon={faFontAwesome} size="2xl" />}
                            </Descriptions.Item>

                        )}
                    </Descriptions>
                    <Descriptions title="Location PDF" bordered >
                        {items2.length > 0 && items2.map((item2) =>
                            <Descriptions.Item label={item2.location + ' - [ ' + item2.timestamp + ' ]'} key={item2.id}>
                                {item2.type === 'pdf' && <FontAwesomeIcon data-tooltip-id="my-tooltip-1" data-tooltip-html={item2.tooltip} onClick={() => { downloadReport(item2.filename, item2.type) }} icon={faFilePdf} size="2xl" />}
                            </Descriptions.Item>

                        )}
                    </Descriptions>

                    <Descriptions title="Location CSV" bordered >
                        {items3.length > 0 && items3.map((item3) =>
                            <Descriptions.Item label={item3.location + ' - [ ' + item3.timestamp + ' ]'} key={item3.id}>
                                {item3.type === 'csvlocations' && <FontAwesomeIcon data-tooltip-id="my-tooltip-1" data-tooltip-html={item3.tooltip}  onClick={() => { downloadReport(item3.filename, item3.type) }} icon={faFileCsv} size="2xl" />}
                            </Descriptions.Item>
                        )}
                    </Descriptions>

                    <Descriptions title="NO Barcode" bordered >
                        {items4.length > 0 && items4.map((item4) =>
                            <Descriptions.Item label={item4.location + ' - [ ' + item4.timestamp + ' ]'} key={item4.id}>
                                {item4.type === 'nobarcodecsv' && <FontAwesomeIcon data-tooltip-id="my-tooltip-1" data-tooltip-html={item4.tooltip} onClick={() => { downloadReport(item4.filename, item4.type) }} icon={faFileCsv} size="2xl" />}
                                {item4.type === 'nobarcodepdf' && <FontAwesomeIcon data-tooltip-id="my-tooltip-1" data-tooltip-html={item4.tooltip} onClick={() => { downloadReport(item4.filename, item4.type) }} icon={faFilePdf} size="2xl" />}
                            </Descriptions.Item>
                        )}
                    </Descriptions>

                    <Descriptions title="SPLIT" bordered >
                        {items5.length > 0 && items5.map((item5) =>
                            <Descriptions.Item label={item5.location + ' - [ ' + item5.timestamp + ' ]'} key={item5.id}>
                                {item5.type === 'splitcsv' && <FontAwesomeIcon data-tooltip-id="my-tooltip-1" data-tooltip-html={item5.tooltip} onClick={() => { downloadReport(item5.filename, item5.type) }} icon={faFileCsv} size="2xl" />}
                                {item5.type === 'splitpdf' && <FontAwesomeIcon data-tooltip-id="my-tooltip-1" data-tooltip-html={item5.tooltip} onClick={() => { downloadReport(item5.filename, item5.type) }} icon={faFilePdf} size="2xl" />}
                            </Descriptions.Item>
                        )}
                    </Descriptions>

                </Col>
                <Col span={7} className="fileuploadboxesbottom"></Col>
            </Row>

            {/* Tool Tips */}
            <ReactTooltip id="my-tooltip-1" place="bottom" />
            <ReactTooltip id="my-tooltip-2" place="bottom" />
        </>
    );
}

async function filterArray(items, reportsCSV, reportsXLS, reportsJSON, reportsLOG) {
    //Build array  
    let combined;

    let filter2 = [];
    let filter3 = [];
    let filter4 = [];
    let filter5 = [];
    let filter6 = [];

    if (reportsCSV) {
        filter2 = items.filter(function (el) {
            return el.type === "csv";
        });
    }

    if (reportsXLS) {
        filter3 = items.filter(function (el) {
            return el.type === "xls";
        });
    }

    if (reportsJSON) {
        filter4 = items.filter(function (el) {
            return el.type === "json";
        });
    }

    if (!reportsCSV) {
        filter5 = items.filter(function (el) {
            return el.location === "All Claims";
        });
    }

    if (reportsLOG) {
        filter6 = items.filter(function (el) {
            return el.type === "log";
        });
    }

    combined = [].concat(filter3, filter4, filter2, filter5, filter6);

    return combined;
}

async function filterArray2(items, reportsPDF) {
    //Build array  
    let combined;
    let filter1 = [];

    if (reportsPDF) {
        filter1 = items.filter(function (el) {
            return el.type === "pdf";
        });
    }

    combined = [].concat(filter1);

    return combined;
}

async function filterArray3(items, reportsPDF) {
    //Build array  
    let combined;
    let filter1 = [];

    if (reportsPDF) {
        filter1 = items.filter(function (el) {
            return el.type === "csvlocations";
        });
    }

    combined = [].concat(filter1);

    return combined;
}

async function filterArray4(items, reportsNOBARCODE) {
    //Build array  
    let combined;
    let filter1 = [];
    let filter2 = [];

    if (reportsNOBARCODE) {
        filter1 = items.filter(function (el) {
            return el.type === "nobarcodecsv";
        });
        filter2 = items.filter(function (el) {
            return el.type === "nobarcodepdf";
        });
    }

    combined = [].concat(filter1, filter2);

    return combined;
}

async function filterArray5(items, reportsSPLIT) {
    //Build array  
    let combined;
    let filter1 = [];
    let filter2 = [];

    if (reportsSPLIT) {
        filter1 = items.filter(function (el) {
            return el.type === "splitcsv";
        });
        filter2 = items.filter(function (el) {
            return el.type === "splitpdf";
        });
    }

    combined = [].concat(filter1, filter2);

    return combined;
}


import "../css/Categories.css";
import React, { useState, useEffect } from 'react';
import { Descriptions, Col, Row, Alert } from 'antd';
import Axios from 'axios';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { setAuth } from '../redux/appmgtSlice'

//Environment
import { chooseEnvironment } from '../utils/chooseEnvironment';

export const Categories = (propz) => {

    //Redux
    const dispatch = useDispatch();
    const token = useSelector((state) => state.appmgt.token);

    //Environment
    let envUrl = chooseEnvironment();

    //State
    const [categories, setCategories] = useState([]);
    const [functionState, setFunctionState] = useState();

    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            "x-auth-token": token,
        },
    };


    useEffect(() => {

        const values = {
            "company": process.env.REACT_APP_COMPANY
        };

        async function runReports() {
            await Axios.post(envUrl + '/api/categories', values, customConfig)
                .then(function (response) {
                    if (response.data.token) {
                        setCategories(response.data.categories);
                    } else {
                        dispatch(setAuth(response.data.token));
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
                .finally(function () {
                    // always executed
                });
        }

        runReports();

        //get sysparams
        async function getSysparams() {
            await Axios.post(envUrl + '/api/getSysparams', values, customConfig)
                .then(function (response) {
                    if (response.data.token) {
                        setFunctionState(response.data.params.functionState);
                    } else {
                        dispatch(setAuth(response.data.token));
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
                .finally(function () {
                    // always executed
                });
        }

        getSysparams();

        // eslint-disable-next-line
    }, [])
    if (functionState === 'stockcount') {
        return (
            <>
                <Row className="fileuploadinfo">
                    <Col span={1} className="fileuploadinfo"></Col>
                    <Col span={21} className="fileuploadinfo">
                        <Descriptions title="Total Claims - by Category" bordered >
                            {categories.length > 0 && categories.map((item) =>
                                <Descriptions.Item label={item.category} key={item.key}>
                                    {item.claims}
                                </Descriptions.Item>
                            )}
                        </Descriptions>
                    </Col>
                    <Col span={2} className="fileuploadinfo"></Col>
                </Row>
                <Row className="fileuploadinfo">
                    <Col span={1} className="fileuploadinfo"></Col>
                    <Col span={16} className="fileuploadinfo">
                        <Alert
                            message="Tip"
                            description="This page is showing the total number of claims [ products / barcodes / SKU ] in each category."
                            type="info"
                            showIcon
                        />
                    </Col>
                    <Col span={7} className="fileuploadinfo"></Col>
                </Row>
            </>
        );
    }

    if (functionState === 'stockscan') {
        return (
            <>
                <Row >
                    <Col span={1} className="fileuploadinfo"></Col>
                    <Col span={16} className="fileuploadinfo">
                        <Descriptions title="Upload Claims Data" bordered>

                        </Descriptions>
                    </Col>
                    <Col span={7} className="fileuploadinfo"></Col>
                </Row>
                <Row >
                    <Col span={1} className="fileuploadinfo"></Col>
                    <Col span={16} className="fileuploadinfo">
                        <Alert
                            message="Tip"
                            description="This function is disabled during Product Scan. It is only available during Product Count."
                            type="info"
                            showIcon
                        />
                    </Col>
                    <Col span={7} className="fileuploadinfo"></Col>
                </Row>
            </>
        );
    }
};


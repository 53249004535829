import "./css/App.css";
import { Route, Routes } from "react-router-dom";

//Components
import { Home } from "./authenticated/Home.js";
import { Users } from "./authenticated/Users.js";
import { ClaimUpload } from "./authenticated/ClaimUpload.js";
import { Distribution } from "./authenticated/Distribution.js";
import { Categories } from "./authenticated/Categories.js";
import { LiveCounting } from "./authenticated/LiveCounting.js";
import { ClaimDetails } from "./authenticated/ClaimDetails.js";
import { AddUserForm } from "./authenticated/AddUserForm.js";
import { TransactionDetails } from './authenticated/TransactionDetails.js';
import { Settings } from './authenticated/Settings.js';
import { ManageCycle } from './authenticated/ManageCycle.js';
import { Status } from './authenticated/Status.js'

//Reports
import { Reports } from "./authenticated/reports/Reports.js";

//Utils
import { ProtectedLayout } from './utils/ProtectedLayout.js'
import { HomeLayout } from "./utils/HomeLayout.js";

//Login
import { LoginPage } from './auth/LoginPage.js';

//Get Env Variables
const utilserverip = process.env.REACT_APP_UTILSERVERIP;
const utilserverport = process.env.REACT_APP_UTILSERVERPORT;

const App = () => {

    return (
        <Routes>
            <Route element={<HomeLayout />}>
                <Route path="/" element={<LoginPage />} />
            </Route>
            <Route path="/dashboard" element={<ProtectedLayout />}>
                <Route path="home" element={<Home />} />
                <Route path="users" element={<Users />} />
                <Route path="claimUpload" element={<ClaimUpload propz={{ utilserverip: utilserverip, utilserverport: utilserverport }} />} />
                <Route path="distribution" element={<Distribution propz={{ utilserverip: utilserverip, utilserverport: utilserverport }} />} />
                <Route path="categories" element={<Categories propz={{ utilserverip: utilserverip, utilserverport: utilserverport }} />} />

                <Route path="liveCounting" element={<LiveCounting propz={{ utilserverip: utilserverip, utilserverport: utilserverport }} />} />
                <Route path="claimDetails" element={<ClaimDetails propz={{ utilserverip: utilserverip, utilserverport: utilserverport }} />} />
                <Route path="transactionDetails" element={<TransactionDetails propz={{ utilserverip: utilserverip, utilserverport: utilserverport }} />} />
                <Route path="addUser" element={<AddUserForm />} />
                <Route path="settings" element={<Settings />} />
                <Route path="managecycle" element={<ManageCycle />} />
                <Route path="status" element={<Status />} />

                {/* Reports */}
                <Route path="reports" element={<Reports propz={{ utilserverip: utilserverip, utilserverport: utilserverport }} />} />

            </Route>
        </Routes>
    );
};

export default App;
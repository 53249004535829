import React from "react";
import { Bar } from "@ant-design/plots";

export const Value = ({claimed,counted}) => {
  const data = [
    {
      year: "Claimed €",
      value: claimed,
    },
    {
      year: "Counted €",
      value: counted,
    },
  ];
  const config = {
    height: 200,
    data,
    xField: "value",
    yField: "year",
    seriesField: "year",
    legend: {
      position: "top-left",
    }, 
    color: ["#9a8c98", "#52796f"],
  };
  return <Bar {...config} />;
};

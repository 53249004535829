import "../css/Distribution.css";
import React, { useState, useEffect } from 'react';
import { Descriptions, Col, Row, Alert } from 'antd';
import Axios from 'axios';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { setAuth } from '../redux/appmgtSlice'

//Environment
import { chooseEnvironment } from '../utils/chooseEnvironment';

export const Distribution = (propz) => {

    //Redux
    const dispatch = useDispatch();
    const token = useSelector((state) => state.appmgt.token);

    //Environment
    let envUrl = chooseEnvironment();

    //State
    const [distributions, setDistribution] = useState([]);
    const [functionState, setFunctionState] = useState();

    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            "x-auth-token": token,
        },
    };


    useEffect(() => {

        const values = {
            "company": process.env.REACT_APP_COMPANY
        };

        async function runReports() {
            await Axios.post(envUrl + '/api/distribution', values, customConfig)
                .then(function (response) {
                    
                    if (response.data.token) {
                        setDistribution(response.data.distribution);
                    } else {
                        dispatch(setAuth(response.data.token));
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });             
        }

         runReports();

        //get sysparams
        async function getSysparams() {
            await Axios.post(envUrl + '/api/getSysparams', values, customConfig)
                .then(function (response) {
                    if (response.data.token) {
                        setFunctionState(response.data.params.functionState);
                    } else {
                        dispatch(setAuth(response.data.token));
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        }

        getSysparams();

        // eslint-disable-next-line
    }, [])
    if (functionState === 'stockcount') {
        return (
            <>
                <Row >
                    <Col span={1} className="fileuploadinfo"></Col>
                    <Col span={16} className="fileuploadinfo">
                        <Descriptions title="Total Claims - by Location" bordered >
                            {distributions.length > 0 && distributions.map((item) =>
                                <Descriptions.Item label={item.location} key={item.key}>
                                    {item.claims}
                                </Descriptions.Item>
                            )}
                        </Descriptions>
                    </Col>
                    <Col span={7} className="fileuploadinfo"></Col>
                </Row>
                <Row>
                    <Col span={1} className="fileuploadinfo"></Col>
                    <Col span={16} className="fileuploadinfo">
                        <Alert
                            message="Tip"
                            description="This page is showing the total number of claims [ products / barcodes / SKU ] at each location. This function can be used to decide on the number of counting personnel at each location."
                            type="info"
                            showIcon
                        />
                    </Col>
                    <Col span={7} className="fileuploadinfo"></Col>
                </Row>
            </>
        );
    }

    if (functionState === 'stockscan') {
        return (
            <>
                <Row >
                    <Col span={1} className="fileuploadinfo"></Col>
                    <Col span={16} className="fileuploadinfo">
                        <Descriptions title="Upload Claims Data" bordered>

                        </Descriptions>
                    </Col>
                    <Col span={7} className="fileuploadinfo"></Col>
                </Row>
                <Row >
                    <Col span={1} className="fileuploadinfo"></Col>
                    <Col span={16} className="fileuploadinfo">
                        <Alert
                            message="Tip"
                            description="This function is disabled during Product Scan. It is only available during Product Count."
                            type="info"
                            showIcon
                        />
                    </Col>
                    <Col span={7} className="fileuploadinfo"></Col>
                </Row>
            </>
        );
    }
};


/* eslint-disable jsx-a11y/anchor-is-valid */
import "../css/Users.css";
import { Table, Col, Row, Space, Badge, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { setAuth } from '../redux/appmgtSlice';

//Environment
import { chooseEnvironment } from '../utils/chooseEnvironment';

export function Users() {

    const { Title } = Typography;

    //Redux
    const dispatch = useDispatch();
    const token = useSelector((state) => state.appmgt.token);
    //State
    const [users, setUsers] = useState([]);

    //Environment
    let envUrl = chooseEnvironment();
    const refreshrate = process.env.REACT_APP_USERSREFRESHRATE;

    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            "x-auth-token": token,
        },
    };


    useEffect(() => {

        const values = {
            "company": process.env.REACT_APP_COMPANY
        };

        const interval = setInterval(() => {
           
            Axios.post(envUrl + '/api/getallusers', values, customConfig).then(function (response) {

                if (response.data.token) {
                    let arr = response.data.users;
                    arr.forEach(obj => renameKey(obj, '_id', 'key'));
                    setUsers(response.data.users);                   
                } else {
                    dispatch(setAuth(response.data.token));
                }
            });
        }, refreshrate); //set your time here.

        return () => clearInterval(interval);

        // eslint-disable-next-line
    }, [])

    const deleteUser = (event, username) => {

        event.preventDefault();
        const values = {
            "company": process.env.REACT_APP_COMPANY,
            "username": username
        }
      
        Axios.post(envUrl + '/api/deleteuser', values, customConfig).then((response) => {
            if (response.data.token) {
                notify(response.data.success);
            } else {
                dispatch(setAuth(response.data.token));
            }
        });

    };

    const columns = [
        {
            title: 'Active',
            key: 'active',
            render: (_, record) => record.active ? <Badge status="success" /> : <Badge status="error" />,
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Surname',
            dataIndex: 'surname',
            key: 'surname',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
        },
        {
            title: 'MobileNo',
            dataIndex: 'mobileno',
            key: 'mobileno',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Action',
            dataIndex: 'operation',
            key: 'operation',
            render: (_, record) => (
                <Space size="middle">
                    {record.username !== 'admin' ? <a href="#" onClick={event => deleteUser(event, record.username)}>Delete</a>: ''}
                   
                </Space>
            ),
        },
    ];

    return (
        <>
            <ToastContainer />
            <Row>
                <Col span={21} className="usersboxestop">
                </Col>
                <Col span={2} className="usersboxestop">
                    <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to="/dashboard/addUser"><div className="adduserbutton"> <FontAwesomeIcon icon={faUserPlus} size="xl" /> Add </div></NavLink>
                </Col>
                <Col span={1} className="usersboxestop"></Col>
            </Row>
            <Row>
                <Col span={1} className="usersboxesbottom"></Col>
                <Col span={22} className="usersboxesbottom">
                    <Table dataSource={users} columns={columns} size="small" />
                </Col>
                <Col span={1} className="usersboxesbottom"></Col>
            </Row>
            <Row>
                <Col span={2} className="homerefreshrate"></Col>
                <Col span={20} className="homerefreshrate"><Title level={5}>Auto Refresh - every {refreshrate / 1000} seconds</Title></Col>
                <Col span={2} className="homerefreshrate"></Col>
            </Row>
        </>
    );

}

function notify(check) {

    if (check) {
        toast.success('User Deleted - Success', {
            position: "top-center",
            autoClose: 800,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    } else {
        toast.error('User Not Deleted - Failed', {
            position: "top-center",
            autoClose: 800,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

}

function renameKey(obj, oldKey, newKey) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
}
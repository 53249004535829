export function chooseEnvironment() {
 
    //environment
    const environment = process.env.REACT_APP_ENVIRONMENT;

    //url
    const utilserverip_local = process.env.REACT_APP_LOCAL_UTILSERVERIP;
    const utilserverip_stage = process.env.REACT_APP_STAGE_UTILSERVERIP;
    const utilserverip_production = process.env.REACT_APP_PRODUCTION_UTILSERVERIP;

    //Port
    const utilserverport = process.env.REACT_APP_UTILSERVERPORT; 

    let url = '';

    if (environment === 'LOCAL') {
        url = 'http://' + utilserverip_local + ':' + utilserverport;
    } else if (environment === 'STAGE') {
        url = 'http://' + utilserverip_stage + ':' + utilserverport;
    } else if (environment === 'PRODUCTION') {
        //https://api.whdata.xyz
        url = 'https://' + utilserverip_production;
    }

    return url;
}
import "../css/TransactionDetails.css";
import { Button, Form, Input, Typography, Radio } from 'antd';
import { Col, Row, Table } from 'antd';
import Axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useState } from 'react';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { setAuth } from '../redux/appmgtSlice'

//Environment
import { chooseEnvironment } from '../utils/chooseEnvironment';

export function TransactionDetails() {

    const { Title } = Typography;

    //Redux
    const dispatch = useDispatch();
    const token = useSelector((state) => state.appmgt.token);

    //Environment
    let envUrl = chooseEnvironment();

    //State
    const [value, setValue] = useState(2);
    const [barcodeValue, setBarcodeValue] = useState(false);
    const [skuValue, setSKUValue] = useState(true);
    const [claims, setClaims] = useState([]);

    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            "x-auth-token": token,
        },
    };

    const columns = [
        {
            title: 'Barcode',
            dataIndex: 'barcode_s',
            key: 'barcode_s',
        },
        {
            title: 'SKU',
            dataIndex: 'sku_s',
            key: 'sku_s',
        },
        {
            title: 'Counted Units',
            dataIndex: 'counted_i',
            key: 'counted_i',
        },
        {
            title: 'By',
            dataIndex: 'by_s',
            key: 'by_s',
        },

        {
            title: 'Location',
            dataIndex: 'location_s',
            key: 'location_s',
        },
        {
            title: 'Timestamp',
            dataIndex: 'timestamp_dt',
            key: 'timestamp_dt',

        },
        {
            title: 'Type',
            dataIndex: 'type_s',
            key: 'type_s',
            render: (_, record) => {
                if (record.type_s === 'stockcount') {
                    return 'Added'
                }

                if (record.type_s === 'stockdeletebyuser') {
                    return 'Deleted'
                }
            }
        }

    ];

    const onChange = (e) => {
        setValue(e.target.value);

        if (e.target.value === 1) {
            setBarcodeValue(false);
            setSKUValue(true);
            form.setFieldsValue({ barcode: '', sku: '', });
        }

        if (e.target.value === 2) {
            setBarcodeValue(true);
            setSKUValue(false);
            form.setFieldsValue({ barcode: '', sku: '', });
        }
    };

    const [form] = Form.useForm();

    const onFinish = (values) => {
     
        Axios.post(envUrl + '/api/getTransactionDetails', values, customConfig).then((response) => {

            if (response.data.token) {

                    if (response.data.transactions.length > 0) {
                        notify(true);
                        //Change _id to key
                        const arr = response.data.transactions;
                        arr.forEach(obj => renameKey(obj, 'id', 'key'));
                        setClaims(arr);
                    } else {
                        console.log('array is empty'); notify(false);
                        setClaims([]);
                    }
            
            } else {
                dispatch(setAuth(response.data.token));
            };

        });

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>

            <ToastContainer
                position="top-center"
                autoClose={300}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

            <Row>
                <Col span={1} className="claimdetailsheader">

                </Col>
                <Col span={12} className="claimdetailsheader">
                    <Title level={5}>Search Transactions [ As counted by Personell ]</Title>
                </Col>
                <Col span={11} className="claimdetailsheader">

                </Col>
            </Row>
            <Row>
                <Col span={1} className="claimdetailstop">
                </Col>
                <Col span={12} className="claimdetailstop">

                    <Form
                        form={form}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 12 }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        style={{
                            maxWidth: 400,
                        }}

                        initialValues={{ company: process.env.REACT_APP_COMPANY, by: 1 }}
                    >
                        <Form.Item name="company" label="Company" hidden rules={[{ required: true, message: 'Company is required' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name="by" label="Search By">
                            <Radio.Group onChange={onChange} value={value}>
                                <Radio value={1}>Barcode</Radio>
                                <Radio value={2}>SKU</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item name="barcode" label="Barcode">
                            <Input disabled={barcodeValue} placeholder="input search text" style={{ width: 200 }} />
                        </Form.Item>

                        <Form.Item name="sku" label="SKU">
                            <Input disabled={skuValue} placeholder="input search text" style={{ width: 200 }} />
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
                            <Button type="primary" htmlType="submit">
                                Search Claims
                            </Button>
                        </Form.Item>

                    </Form>
                </Col>
                <Col span={11} className="claimdetailstop"></Col>
            </Row>

            <Row>
                <Col span={1} className="claimdetailsbottom"></Col>
                <Col span={22} className="claimdetailsbottom">
                    <Table dataSource={claims} columns={columns} size="small" pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }} />
                </Col>
                <Col span={1} className="claimdetailsbottom"></Col>
            </Row>
        </>
    );
}

function notify(check) {

    if (check) {
        toast.success('Success Data Found - SKU/Barcode', {
            position: "top-center",
            autoClose: 800,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    } else {
        toast.error('No Data Found - SKU/Barcode', {
            position: "top-center",
            autoClose: 800,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

}

function renameKey(obj, oldKey, newKey) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
}
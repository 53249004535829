import { Row, Col, Button, Form, Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone, UserOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Logo
import logo from '../assets/login.png';

//Redux
import { useDispatch } from 'react-redux';
import { setAuth, setToken, setUsers, setTokenExpiryTime, setClaimDetails, setTransactions, setReportsPDF, setReportsCSV, setReportsXLS, setReportsJSON, setReportsLOG, setReportsNOBARCODE, setReportsSPLIT } from '../redux/appmgtSlice';

//Css
import "../css/LoginPage.css";

//Environment
import { chooseEnvironment } from '../utils/chooseEnvironment';

export function LoginPage() {

    //Environment
    let envUrl = chooseEnvironment();

    const dispatch = useDispatch()

    const [form] = Form.useForm();

   // console.log('environent: ', envUrl);

    const onFinish = (values) => {

        const customConfig = {
            headers: {
                'Content-Type': 'application/json'
            },
        };

        Axios.post(envUrl + '/api/auth', values, customConfig).then((response) => {
            notify(response.data.active);
            if (response.data.active) {
                console.log('found active - allowed to login');
           
                dispatch(setAuth(true));
                dispatch(setToken(response.data.token));

                // Packages //
                dispatch(setUsers(response.data.users));
                dispatch(setTokenExpiryTime(response.data.tokenexpirytime));
                dispatch(setClaimDetails(response.data.claimdetails));
                dispatch(setTransactions(response.data.transactions));

                //Reports
                dispatch(setReportsPDF(response.data.reportsPDF));
                dispatch(setReportsCSV(response.data.reportsCSV));
                dispatch(setReportsXLS(response.data.reportsXLS));
                dispatch(setReportsJSON(response.data.reportsJSON));

                dispatch(setReportsLOG(response.data.reportsLOG));
                dispatch(setReportsNOBARCODE(response.data.reportsNOBARCODE));
                dispatch(setReportsSPLIT(response.data.reportsSPLIT));   

            }
        }).catch(function (error) {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
            console.log(error.config);
        });

        //Clean Fields
        form.resetFields();

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const layout = {
        labelCol: {
            span: 0,
        },
        wrapperCol: {
            span: 24,
        },
    };

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={300}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

            <Row>
                <Col span={24} className='topbox'></Col>
            </Row>
            <Row>
                <Col span={9}></Col>
                <Col span={6} className='outerbox'>
                    <Row>
                        <Col>
                            <img src={logo} alt="Logo" />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form
                                {...layout}
                                form={form}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                initialValues={{ company: process.env.REACT_APP_COMPANY }}>

                                <Form.Item name="company" label="Company" hidden>
                                    <Input />
                                </Form.Item>

                                <Form.Item name="username" rules={[{ required: true, message: 'Username is required' }]} wrapperCol={{ offset: 0, span: 24 }}>
                                    <Input size="large" placeholder="Enter your username" prefix={<UserOutlined className="site-form-item-icon" />} />
                                </Form.Item>

                                <Form.Item name="password" rules={[{ required: true, message: 'Password is required' }]} wrapperCol={{ offset: 0, span: 24 }}>
                                    <Input.Password size="large" placeholder="Enter your password" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                                </Form.Item>

                                <Form.Item>
                                    <Button className="loginbutton" size="large" type="primary" htmlType="submit">
                                        Log In
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>


                </Col>
                <Col span={9}></Col>
            </Row>
        </>
    );

}

function notify(check) {

    if (!check) {
        toast.error('Login Failed', {
            position: "top-center",
            autoClose: 800,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    };
}
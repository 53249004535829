import "../css/ClaimUpload.css";
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload, Descriptions, Col, Row, Alert, Modal, Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv, faFileLines, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import Axios from 'axios';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import React, { useState, useEffect } from 'react';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { setAuth } from '../redux/appmgtSlice'

//Environment
import { chooseEnvironment } from '../utils/chooseEnvironment';

//Environment
let envUrl = chooseEnvironment();

const { Dragger } = Upload;

async function getLogfile() {

    await Axios({
        url: envUrl + '/api/logfile',
        method: 'GET',
        responseType: 'blob'
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'uploadlogfile.log');
        document.body.appendChild(link);
        link.click();
    });
}

async function getBadLogFile() {

    await Axios({
        url: envUrl + '/api/badlogfile',
        method: 'GET',
        responseType: 'blob'
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'badlog.csv');
        document.body.appendChild(link);
        link.click();
    });
}

async function getBadRawFile() {

    await Axios({
        url: envUrl + '/api/badrawfile',
        method: 'GET',
        responseType: 'blob'
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'badraw.csv');
        document.body.appendChild(link);
        link.click();
    });
}


export const ClaimUpload = (propz) => {

    //False - Delete All data reupload , True Append Data
    const [appendState, setAppendState] = useState(false);

    const [appendToggle, setAppendToggle] = useState(false);

    function toggleScan() {
        if (!appendState) {
            setAppendToggle(true);
            setAppendState(true);
        }

        if (appendState) {
            setAppendToggle(false);
            setAppendState(false);
        }
    };

    //Company
    let company = process.env.REACT_APP_COMPANY;

    //Redux
    const dispatch = useDispatch();
    const token = useSelector((state) => state.appmgt.token);

    //State
    const [functionState, setFunctionState] = useState();

    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            "x-auth-token": token,            
        },
    };

    const data = {
        "company": company,
        "append": appendState
    };

    const props = {
        name: 'recfile',
        accept: '.csv',
        multiple: false,
        action: envUrl + '/api/uploadclaims',
        showUploadList: false,
        data: data,
        headers: {
            "x-auth-token": token,
        },
        onChange(info) {

            //console.log(info);

            const { status } = info.file;

            if (status === 'done') {
                const token = info.file.response.token;

                if (token) {
                    const status = info.file.response.status

                    if (status === 'toolarge') {
                        message.error(`${info.file.name} file is too large. Please upload file less than 40 Mb - approx 500 thousand rows`);
                    } else {

                        if (status) {
                            message.success(`${info.file.name} file uploaded successfully.`);
                        } else {
                            message.error(`${info.file.name} file upload failed.`);
                        }
                    }
                } else {
                    message.error('Session Expired - Not Authorized');
                    dispatch(setAuth(false));
                }
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            // console.log('Dropped files', e.dataTransfer.files);
        },
    };

    useEffect(() => {

        const values = {
            "company": process.env.REACT_APP_COMPANY
        };

        async function runReports() {
            await Axios.post(envUrl + '/api/getSysparams', values, customConfig)
                .then(function (response) {
                    if (response.data.token) {
                        setFunctionState(response.data.params.functionState);
                    } else {
                        dispatch(setAuth(response.data.token));
                    };
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

        runReports();
        // eslint-disable-next-line
    }, [])

    if (functionState === 'stockcount') {

        Modal.info({
            title: 'Notification',
            content: (
                <div>
                    <p>Please be cautios when using this feature.</p>
                    <br></br>
                    <p>Every new file upload will delete all the old data and</p>
                    <p>refresh the system with the newly uploaded file.</p>
                    <br></br>
                    <p>Every new file upload will also delete all previous reports.</p>
                    <br></br>
                    <p>This means you are re-starting all stock take procedures afresh.</p>
                    <br></br>
                    <p>Please make sure you stopped all personnel from stock counting before carrying out this procedure.</p>
                    <br></br>
                    <p>Failure to follow all the above will result in complete loss of work and data.</p>
                </div>
            ),
            onOk() {
                console.log('ok');
            },
        });

        return (

            <>
                <ToastContainer />

                <Row >
                    <Col span={1} className="fileuploadboxestop"></Col>
                    <Col span={16} className="fileuploadboxestop">
                        <Descriptions title="Upload Claims Data" bordered>
                            <Descriptions.Item label="Action" span={3}>Use the function to upload new claims data.</Descriptions.Item>
                            <Descriptions.Item label="Append" span={3}>
                                <Switch disabled={false} checked={appendToggle} style={{ background: "#5b5981" }} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} onChange={toggleScan} />{appendState ? ' Enabled' : ' Disabled'}
                            </Descriptions.Item>
                            <Descriptions.Item label="" span={3}>
                                <Dragger {...props}>
                                    <p className="ant-upload-text">Drag claims.csv file to this area to upload</p>

                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>

                                    <p className="ant-upload-text">The system accepts only files of type *.csv</p>
                                    <p className="ant-upload-text">A repeated file upload will replace the previous one</p>

                                    <p className="ant-upload-hint">
                                        Strictly prohibited from uploading unauthorized company data or other banned files.
                                    </p>
                                </Dragger>

                            </Descriptions.Item>
                            <Descriptions.Item label="Results Log File" span={3}>
                                <FontAwesomeIcon icon={faFileLines} size="2xl" onClick={() => { getLogfile() }} />
                            </Descriptions.Item>
                            <Descriptions.Item label="Bad Records [Log]" span={3}>
                                <FontAwesomeIcon icon={faFileLines} size="2xl" onClick={() => { getBadLogFile() }} />
                            </Descriptions.Item>
                            <Descriptions.Item label="Bad Records [Raw]" span={3}>
                                <FontAwesomeIcon icon={faFileLines} size="2xl" onClick={() => { getBadRawFile() }} />
                            </Descriptions.Item>

                        </Descriptions>
                    </Col>
                    <Col span={7} className="fileuploadboxestop"></Col>
                </Row>
                <Row >
                    <Col span={1} className="fileuploadboxesbottom"></Col>
                    <Col span={16} className="fileuploadboxesbottom">
                        <Descriptions title="Help" bordered>
                            <Descriptions.Item label="Data Cleansing Tutorial" span={3}><a href='/downloads/docs/trakii_data_cleansing v1.0.pdf' download><FontAwesomeIcon icon={faFilePdf} size="2xl" /></a></Descriptions.Item>
                            <Descriptions.Item label="Sample File [Scan]" span={3}><a href='/downloads/claims_scan.csv' download><FontAwesomeIcon icon={faFileCsv} size="2xl" /></a></Descriptions.Item>
                            <Descriptions.Item label="Sample File [Count]" span={3}><a href='/downloads/claims_count.csv' download><FontAwesomeIcon icon={faFileCsv} size="2xl" /></a></Descriptions.Item>
                            <Descriptions.Item label="Barcode" span={3}> The scanned barcode on the packaging per unit item.</Descriptions.Item>
                            <Descriptions.Item label="SKU" span={3}> [ Stock Keeping Unit ]  The assigned code (usually eight alphanumeric digits) to products to keep track of stock levels internally</Descriptions.Item>
                            <Descriptions.Item label="Description" span={3}> The unit description Example - [ Brand ] Fresh Milk 2.5% Fat</Descriptions.Item>
                            <Descriptions.Item label="Category" span={3}>The unit category Example - Dairy</Descriptions.Item>
                            <Descriptions.Item label="Claim" span={3}>The number of units you claim you have available in storage at this point in time.</Descriptions.Item>
                            <Descriptions.Item label="Location" span={3}>The location of the units. Note the system can handle that the same unit barcode is present at different locations. [ see Sample file ] </Descriptions.Item>
                            <Descriptions.Item label="Unitvalue" span={3}>The unit cost of the item.</Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col span={7} className="fileuploadboxesbottom"></Col>
                </Row>
            </>
        );
    }

    if (functionState === 'stockscan') {
        return (
            <>
                <Row >
                    <Col span={1} className="fileuploadinfo"></Col>
                    <Col span={16} className="fileuploadinfo">
                        <Descriptions title="Upload Claims Data" bordered>

                        </Descriptions>
                    </Col>
                    <Col span={7} className="fileuploadinfo"></Col>
                </Row>
                <Row >
                    <Col span={1} className="fileuploadinfo"></Col>
                    <Col span={16} className="fileuploadinfo">
                        <Alert
                            message="Tip"
                            description="Claim Upload function is disabled during Product Scan. Claim Upload is only available during Product Count."
                            type="info"
                            showIcon
                        />
                    </Col>
                    <Col span={7} className="fileuploadinfo"></Col>
                </Row>
                <Row >
                    <Col span={1} className="fileuploadwarning"></Col>
                    <Col span={16} className="fileuploadwarning">

                        <Alert
                            message="Warning"
                            description="Please make sure you have the required training and know the procedure before proceeding to settings. Wrong use of the Claim Upload function can result in complete loss of work & data."
                            type="warning"
                            showIcon
                        />
                    </Col>
                    <Col span={7} className="fileuploadwarning"></Col>
                </Row>
            </>
        )
    }

};
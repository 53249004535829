import { Button, Form, Input, Typography, Select, InputNumber, Col, Row, Alert } from 'antd';
import Axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { setAuth } from '../redux/appmgtSlice'
import React, { useEffect, useState } from 'react';

//Environment
import { chooseEnvironment } from '../utils/chooseEnvironment';

export function AddUserForm() {

    const { Title } = Typography;

    //Redux
    const dispatch = useDispatch();
    const token = useSelector((state) => state.appmgt.token);
    const usersRedux = useSelector((state) => state.appmgt.users);

    //State
    const [users, setUsers] = useState([]);
    const [show, setShow] = useState(false);
    const refreshrate = process.env.REACT_APP_USERSREFRESHRATE;

    //Environment
    let envUrl = chooseEnvironment();

    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            "x-auth-token": token,
        },
    };

    const [form] = Form.useForm();

    useEffect(() => {

        const values = {
            "company": process.env.REACT_APP_COMPANY
        };

        const interval = setInterval(() => {

        Axios.post(envUrl + '/api/getallusers', values, customConfig).then(function (response) {

            if (response.data.token) {
                let arr = response.data.users;
                arr.forEach(obj => renameKey(obj, '_id', 'key'));
                setUsers(response.data.users);
                setShow(true);
            } else {
                dispatch(setAuth(response.data.token));
            }
        });

        }, refreshrate); //set your time here.

        return () => clearInterval(interval);

        // eslint-disable-next-line
    }, []);

    const onFinish = (values) => {

        console.log('Success:', JSON.stringify(values));


        Axios.post(envUrl + '/api/createuser', values, customConfig).then((response) => {

            if (response.data.token) {
                notify(response.data.success);
            } else {
                dispatch(setAuth(response.data.token));
            }
        });

        //Clean Fields
        form.resetFields();

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    if (users.length >= usersRedux) {      
        return (
            <> 
                <Row >
                    <Col span={1} className="fileuploadinfo"></Col>
                    <Col span={16} className="fileuploadinfo">
                        <Alert
                            message="Tip"
                            description="Unable to add more users. User agreement - limit reached."
                            type="info"
                            showIcon
                        />
                    </Col>
                    <Col span={7} className="fileuploadinfo"></Col>
                </Row>  
            </>
        )
    }


     if (users.length < usersRedux && show) {
        return (
            <>

                <ToastContainer
                    position="top-center"
                    autoClose={300}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />

                <Row>
                    <Col span={1} className="useraddformheader">

                    </Col>
                    <Col span={12} className="useraddformheader">
                        <Title level={5}>Add User</Title>
                    </Col>
                    <Col span={11} className="useraddformheader">

                    </Col>
                </Row>
                <Row>
                    <Col span={1} className="useraddformtop">
                    </Col>
                    <Col span={12} className="useraddformtop">
                        <Form
                            form={form}
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 12 }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            style={{
                                maxWidth: 400,
                            }}

                            initialValues={{ role: 'stockcount', company: process.env.REACT_APP_COMPANY, active: true }}
                        >
                            <Form.Item name="company" label="Company" hidden rules={[{ required: true, message: 'Company is required' }]}>
                                <Input />
                            </Form.Item>

                            <Form.Item name="active" label="Active" hidden rules={[{ required: true, message: 'Active is required' }]}>
                                <Input />
                            </Form.Item>

                            <Form.Item name="username" label="Username" rules={[{ required: true, message: 'Username is required' }]}>
                                <Input showCount maxLength={8} />
                            </Form.Item>

                            <Form.Item name="password" label="Password" rules={[{ required: true, message: 'Password is required' }]}>
                                <Input.Password showCount maxLength={10} />
                            </Form.Item>

                            <Form.Item name="role" label="Role" rules={[{ required: true, message: 'Role is required' }]}>
                                <Select
                                    style={{ width: 120 }}
                                    options={[
                                        { value: 'stockcount', label: 'stock-count' },
                                        { value: 'uiadmin', label: 'uiadmin' }
                                    ]}
                                />
                            </Form.Item>

                            <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Name is required' }]}>
                                <Input />
                            </Form.Item>

                            <Form.Item name="surname" label="Surname" rules={[{ required: true, message: 'Surname is required' }]}>
                                <Input />
                            </Form.Item>

                            <Form.Item name="email" label="Email" rules={[{ required: true, type: "email", message: 'Email is required' }]}>
                                <Input />
                            </Form.Item>

                            <Form.Item name="mobileno" label="Mobile No" rules={[{ required: true, message: 'Mobile number is required' }]}>
                                <InputNumber style={{ width: 150 }} maxLength={8} />
                            </Form.Item>

                            <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>

                        </Form>
                    </Col>
                    <Col span={11} className="useraddformtop"></Col>
                </Row>
            </>
        );
    } 

}

function notify(check) {

    if (check) {
        toast.success('User creation - Success', {
            position: "top-center",
            autoClose: 800,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    } else {
        toast.error('User creation - Failed', {
            position: "top-center",
            autoClose: 800,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

}

function renameKey(obj, oldKey, newKey) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
}
import '../css/Settings.css'
import { Switch, Row, Col, Button, Descriptions } from 'antd';
import { CheckOutlined, CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import Axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { setAuth } from '../redux/appmgtSlice'

//Css
import 'react-toastify/dist/ReactToastify.css';

//Environment
import { chooseEnvironment } from '../utils/chooseEnvironment';

export function Settings() {

    //Redux
    const dispatch = useDispatch();
    const token = useSelector((state) => state.appmgt.token);

    //Environment
    let envUrl = chooseEnvironment();

    //State
    const [scan, setScan] = useState(false);
    const [count, setCount] = useState(true);
    let [expiry, setExpiry] = useState(false);
    let [category, setCategory] = useState(false);
    const [functionState, setFunctionState] = useState();

    //Enable //Disable
    const [categoryState, setCategoryState] = useState(true);
    const [expiryState, setExpiryState] = useState(false);
    const [countState, setCountState] = useState(true);
    const [scanState, setScanState] = useState(true);
    const [saveState, setSaveState] = useState(true);

    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            "x-auth-token": token,
        },
    };

    useEffect(() => {

        const values = {
            "company": process.env.REACT_APP_COMPANY
        };

        async function runSysparams() {
            await Axios.post(envUrl + '/api/getSysparams', values, customConfig)
                .then(function (response) {

                    if (response.data.token) {
                        if (response.data.params.functionState === 'stockscan') {
                            enableFields();
                            toggleSetScan();
                            setCategory(response.data.params.category);
                        } else if (response.data.params.functionState === 'stockcount') {
                            enableFields();
                            toggleSetCount();
                            setExpiry(response.data.params.expiry);
                        }
                    } else {
                        dispatch(setAuth(response.data.token));
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
                .finally(function () {
                    // always executed
                });
        }

        runSysparams();
        // eslint-disable-next-line
    }, [])

    const onFinish = () => {

        let doc = {
            "company": process.env.REACT_APP_COMPANY,
            "expiry": expiry,
            "category": category,
            "functionState": functionState
        }

        console.log(doc);

        Axios.post(envUrl + '/api/updateSysparams', doc, customConfig).then((response) => {
                      
            if (response.data.token) {
                notify(true);
            } else {
                dispatch(setAuth(response.data.token));
            }

        }).catch(function (error) {
            console.log(error.toJSON());
            notify(false);
        });

    };

    function enableFields() {
        setCategoryState(false);
        setExpiryState(false);
        setCountState(false);
        setScanState(false);
        setSaveState(false);
    }

    function toggleSetScan() {
        setFunctionState('stockscan');
        setCount(true);
        setScan(false);
        setExpiry(false);
        setExpiryState(false);
        setCategoryState(false);
    }

    function toggleSetCount() {
        setFunctionState('stockcount');
        setCount(false);
        setScan(true);
        setCategory(false);
        setExpiryState(false);
        setCategoryState(true);
    }

    const toggleScan = () => {
        if (scan) {
            toggleSetScan();
        } else {
            toggleSetCount();
        }
    };

    const toggleCount = () => {
        if (count) {
            toggleSetCount();
        } else {
            toggleSetScan();
        }
    };


    const toggleExpiry = () => {
        if (expiry) {
            setExpiry(false);
        } else {
            setExpiry(true);
        }
    };

    const toggleCategory = () => {
        if (category) {
            setCategory(false);
        } else {
            setCategory(true);
        }
    };

    return (
        <>

            <ToastContainer
                position="top-center"
                autoClose={300}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

            <Row>
                <Col span={1} className="settingsheader"></Col>
                <Col span={12} className="settingsheader">
                    <Descriptions title="Application Settings" layout="horizontal" bordered>

                        <Descriptions.Item label="Product Scan" span={3}>
                            <Switch disabled={countState} checked={count} style={{ background: "#5b5981" }} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} onChange={toggleScan} />{count ? ' Enabled' : ' Disabled'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Product Count" span={3}>
                            <Switch disabled={scanState} checked={scan} style={{ background: "#5b5981" }} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} onChange={toggleCount} />{scan ? ' Enabled' : ' Disabled'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Expiry Feature" span={3}>
                            <Switch disabled={expiryState} checked={expiry} style={{ background: "#5b5981" }} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} onChange={toggleExpiry} />
                        </Descriptions.Item>
                        <Descriptions.Item label="Category Feature" span={3}>
                            <Switch disabled={categoryState} checked={category} style={{ background: "#5b5981" }} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} onChange={toggleCategory} />
                        </Descriptions.Item>
                        <Descriptions.Item span={3}>
                            <Button disabled={saveState} style={{ background: "#5b5981" }} type="primary" onClick={onFinish} icon={< SaveOutlined />}>Save Settings</Button>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
                <Col span={11} className="settingsheader"></Col>
            </Row>
            <Row>
                <Col span={1} className="settingsheader"></Col>
                <Col span={12} className="settingsheader">
                    <Descriptions title="Help" layout="horizontal" bordered>
                        <Descriptions.Item label="Barcode Reader Settings" span={3}><a href='/downloads/docs/trakii_barcode_readers.pdf' download><FontAwesomeIcon icon={faFilePdf} size="2xl" /></a></Descriptions.Item>        
                    </Descriptions>
                </Col>
                <Col span={11} className="settingsheader"></Col>
            </Row>
            <Row>
                <Col span={24} className="settingsbottom"></Col>
            </Row>
            
        </>
    )
}

function notify(check) {

    if (check) {
        toast.success('Settings Update - Success', {
            position: "top-center",
            autoClose: 800,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    } else {
        toast.error('Settings Update - Failed', {
            position: "top-center",
            autoClose: 800,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}